import React from "react";
import User from "./User";
import Filter from "./Filter";
import Grouping from "../../Grouping";

function Header() {
  return (
    <div className="d-flex justify-content-space-between ">
      <User />
      <div className="d-flex">
        <Filter />
      </div>
    </div>
  );
}

export default Header;
