import { Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import DownArrow from '../../../../../styles/svg/date-range.svg'
// import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useCustomSearchParams } from '../../../../../hooks/useCustomSearchParams';
// import { setDateRange } from "../../../../redux/SearchSlice";
import WorkflowDateRangeFilter from "./DateRangeFilter";
function DateRangeFilterButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
//   const dispatch = useDispatch()
  const [Olddate, updateParams, removeSearchParam] =
    useCustomSearchParams("missingStatus");
//   const dateRange = useSelector((state) => state.search.dateRange);
  const open = Boolean(anchorEl);
  const date = JSON.parse(Olddate);

  useEffect(()=>{
    if(date){
      const obj = {
        startDate:  new Date(date.startDate),
        endDate: new Date(date.endDate),
        key: "selection",
      };
    // dispatch(setDateRange(obj));
    }
  },[])

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

  const heading = date
    ? `${moment(date.startDate).format("MM/DD/YYYY")} - ${moment(
        date.endDate
      ).format("MM/DD/YYYY")}`
    : "Select Date Range";
  return (
    <div className="">
      <Button
        variant="outlined"
        color="primary"
        id="epics-board-dropdown"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<img src={DownArrow} style={{paddingRight: "2px"}}/>}
        // className="white-button epics-btn"
        className="filter-btn white-button"
        sx={{
          height: "38px",
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}
      >
        {/* <img src={apicIcon} className="header-filter-icon" alt="not found" />{" "} */}
        <p className="date-range-filter-date">{heading}</p>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={() => setAnchorEl(null)}>Epics 1</MenuItem>
        <MenuItem onClick={() => setAnchorEl(null)}>Epics 2</MenuItem> */}
        <WorkflowDateRangeFilter onClose={() => setAnchorEl(null)} />
      </Menu>
    </div>
  );
}
export default DateRangeFilterButton;
