import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Form from "./Form";
import List from "./List";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { createAttachments } from "../../../../sevices/apiBoards";
import { objectToFormData } from "../../../../utils/helpers";
import toast from "react-hot-toast";
import { object } from "yup";

export default function AnchorTemporaryDrawer({ toggleDrawer, state }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const userData = useSelector((state) => state.users.logInUser);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState([{ name: "" }]);
  const [file, setFile] = useState([{ file: null }]);
  const [attachments, setAttachments] = useState([{ file: null }]);
  // const methods = useForm({
  //   defaultValues: {
  //     board_id: id,
  //     user_id: userData.user_id,
  //     data: [
  //       {
  //         names: "",
  //         image: null,
  //       },
  //     ],
  //   },
  //   // resolver: yupResolver(),
  // });

  // const { handleSubmit, reset } = methods;

  const handleNameChange = (e, index) => {
    const newName = [...name];
    newName[index] = { name: e.target.value };
    setName(newName);
  };
  const handleFileChange = (e, index) => {
    setFile([...e.target.files]);
  };

  const handleSubmit = () => {
    const formData = name.map((item, index) => ({
      name: item.name,
      file: file[index],
    }));

    const fileData = new FormData();
    fileData.append("board_id", id);
    fileData.append("user_id", userData.user_id);

    const namesArray = formData.map((item) => item.name);
    const filesArray = formData.map((item) => item.file);

    fileData.append("names", namesArray.join(", "));
    filesArray.forEach((file) => {
      fileData.append("files", file);
    });

    console.log("Form Data: ", formData);
    createAttachmentMutate(fileData);
  };

  const resetState = () => {
    setName([{ name: "" }]);
    setFile([{ file: null }]);
  };
  const handleDrawerClose = (anchor) => {
    resetState();
    toggleDrawer(anchor, false)();
  };

  const { mutate: createAttachmentMutate, isPending } = useMutation({
    mutationFn: createAttachments,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`attachments-${id}`],
      });
      setLoading(false);
      // reset();
      toast.success("Attachment added successfully");
      resetState();

      //    onClose();
    },
    onError: (error) => {
      console.error("Error Add Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  const list = (anchor) => (
    // <FormProvider {...methods}>
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 520 }}
      role="presentation"
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "700",
          bgcolor: "rgba(242, 246, 254, 1)",
          display: "flex",
          justifyContent: "space-between",
        }}
        id="customized-dialog-title"
        className="blue-text-color attachment-header-text"
      >
        Attachments
        <div style={{ marginRight: "40px" }}>
          <Button
            onClick={() => {
              // handleSubmit(submit);
              handleSubmit();
            }}
            variant="contained"
            className=""
            sx={{
              bgcolor: "rgba(27, 62, 97, 1)",
              minWidth: "80px",
              // width: "100%",
              height: "40px",
              textTransform: "capitalize",
            }}
          >
            {isPending ? "Loading..." : "Add"}
          </Button>
        </div>
      </DialogTitle>
      {/* <Button
        onClick={() => {
          // handleSubmit(submit);
          handleSubmit();
        }}
        variant="contained"
        className=""
        sx={{
          bgcolor: "rgba(27, 62, 97, 1)",
          minWidth: "1px",
          width: "100%",
          height: "52px",
          textTransform: "capitalize",
        }}
      >
        {loading ? "Loading..." : "Add"}
      </Button> */}
      <IconButton
        aria-label="close"
        onClick={() => handleDrawerClose(anchor)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Form
          file={file}
          name={name}
          handleNameChange={handleNameChange}
          handleFileChange={handleFileChange}
        />
        <Box sx={{ height: "24px" }}></Box>
        <List />
      </DialogContent>
    </Box>
    //  </FormProvider>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => handleDrawerClose(anchor)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
