import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams, useSearchParams } from "react-router-dom";
import { useBoardName } from "../../../../utils/reactQuery/boards";
import { Button, Stack } from "@mui/material";

import EpicButton from "../BoardHeader/epicButton/EpicButton";
import SelectDatePicker from "../../../myTask/components/Filters/SelectDatePicker";
import GlobalSearch from "../../../../components/Search";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { searchCard } from "../../../../redux/SearchSlice";
import { filterUser } from "../../../../redux/usersSlice";
import Users from "../BoardHeader/Users/Users";
import { useQueryClient } from "@tanstack/react-query";

const FeedsHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { boardName } = useBoardName(id);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
    }, 300) // 300ms delay
  ).current;

  const [inputValue, setInputValue] = useState("");

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };
  const filterUserId = searchParams.get("search");
  const epicFilter = searchParams.get("epic");
  const clearFilters = () => {
    removeSearchParams(["search", "epic"]);
    dispatch(filterUser(null));
    dispatch(searchCard([]));
  };

  return (
    <>
      <Grid
        container
        xs={12}
        className="remove-container-width fixed-ui feed-header"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          item
          xs={3}
          md={5}
          className="d-flex align-items-center feed-boardName"
        >{`Feeds: ${boardName?.data?.data?.responseData?.board_name}`}</Grid>
        <Grid item xs={9} md={7}>
          <div className="d-flex justify-content-end">
            <Stack spacing={1} direction="row">
              {(filterUserId || epicFilter) && (
                <Button
                  size="small"
                  endIcon={<ClearIcon />}
                  className="capitalize"
                  onClick={clearFilters}
                >
                  Clear Filter
                </Button>
              )}
              <Users
                boardName={boardName}
                boardId={id}
                ownerId={boardName?.data?.data?.responseData?.owner_user_id}
                showAddUser={false}
              />
              {/* <EpicButton boardNameDetails={boardName} /> */}
              <SelectDatePicker />
              <GlobalSearch
                placeholder={"Search..."}
                searchText={inputValue}
                handleChange={handleChange}
              />
            </Stack>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default FeedsHeader;
