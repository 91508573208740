import { Button } from '@mui/material';
import React from 'react'

import AddIcon from "@mui/icons-material/Add";
import FormDialog from '../../../../components/Dialog/FormDialog';
import InviteUserForm from './InviteUserForm';

function InviteUser() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <div className='mr-2'>
      {open && (
        <FormDialog
          open={open}
          handleClose={handleClose}
          title={"Invite User"}
          btnTitle={"Add"}
        >
          <InviteUserForm onClose={handleClose} />
        </FormDialog>
      )}
      <Button
        onClick={handleClickOpen}
        className="capitalize orange-color"
        variant="contained"
        startIcon={<AddIcon />}
      >
        Invite User
      </Button>
    </div>
  );
}

export default InviteUser