import React from "react";
import BoardPage from "../../pages/board";
import CheckBoardAvailability from "../../pages/board/CheckBoardAvailability";
import Dashboard from "../../pages/dashboard";
import Private from "./Private";
import TaskDetail from "../../pages/myTask/components/TaskDetail";
import AdminBoards from "../../pages/admin/board";
import AdminUsers from "../../pages/admin/user";
import { isAdmin } from "../../utils/constants";
import Feeds from "../../pages/board/components/Feeds";
import Workflow from "../../pages/admin/workflow";
import Report from "../../pages/admin/report";
import WatchingComp from "../../pages/watching";

const adminRoutes = [
  {
    path: "/admin-board",
    element: <AdminBoards />,
  },
  {
    path: "/admin-workflow",
    element: <Workflow />,
  },
  {
    path: "/admin-user",
    element: <AdminUsers />,
  },
  {
    path: "/admin-report",
    element: <Report />,
  },
];
export const Pages = {
  element: <Private />,
  children: [
    {
      element: <Dashboard />,
      //   errorElement: <RouterErrorPage />,
      children: [
        {
          path: "/board",
          element: <CheckBoardAvailability />,
        },
        {
          path: "/board/:id",
          element: <BoardPage />,
        },
        {
          path: "/my-task",
          element: <TaskDetail />,
        },
        {
          path: "/feeds/:id",
          element: <Feeds />,
        },
        {
          path: "/watching",
          element: <WatchingComp />,
        },
        ...adminRoutes,
      ],
    },
  ],
};
