import React, { useRef, useEffect, useState } from "react";
import KanbanColumn from "./KanbanColumn";
import { useSearchParams } from "react-router-dom";

function KanbanContainor({
  workflowSteps,
  projects,
  onDragEnter,
  onDragEnd,
  callback,
}) {
  const boardRef = useRef(null);
  const [searchParams] = useSearchParams();
  const isGeneric = searchParams.get("is_generic");
  const [maxHeight, setMaxHeight] = useState(0);
  console.log("GENERICC", projects, isGeneric);

  const handleOnDrag = (e) => {
    const board = boardRef.current;
    if (!board) return;
    const { clientX, clientY } = e;
    const buffer = 50; // Distance from the edge of the container to start scrolling
    const scrollSpeed = 25; // Speed of the scroll

    // Horizontal scroll
    if (clientX - board.offsetLeft < buffer) {
      board.scrollBy({ left: -scrollSpeed, behavior: "smooth" });
    } else if (board.offsetLeft + board.clientWidth - clientX < buffer) {
      board.scrollBy({ left: scrollSpeed, behavior: "smooth" });
    }

    // Vertical scroll
    if (clientY - board.offsetTop < buffer) {
      board.scrollBy({ top: -scrollSpeed, behavior: "smooth" });
    } else if (board.offsetTop + board.clientHeight - clientY < buffer) {
      board.scrollBy({ top: scrollSpeed, behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Calculate the max height based on the tallest column
    const calculateMaxHeight = () => {
      const columnHeights = workflowSteps.map((column) => {
        const columnCards = projects.filter((project) => {
          if (isGeneric == "true") {
            return (
              parseInt(project.generic_step_id, 10) === column.workflow_step_id
            );
          } else {
            return (
              parseInt(project.card.workflow_step_id, 10) ===
              column.workflow_step_id
            );
          }
        });
        return columnCards.length; // Assuming each card has a height of 130px
      });

      const tallestHeight = Math.max(...columnHeights);
      setMaxHeight(tallestHeight);
    };

    calculateMaxHeight();
  }, [workflowSteps, projects, isGeneric]);

  return (
    // <div className="board-card-scroll-btn  d-flex ">
    <div
      // className="board-card-scroll-btn d-flex "
      ref={boardRef}
      style={{
        display: "flex",
        // width: "500px",
        overflowX: "scroll",
        // height: "500px",
        // overflowY: "scroll",
        // maxHeight: `${Math.min(maxHeight, window.innerHeight)}px`,
      }}
      onDragOver={handleOnDrag}
    >
      {workflowSteps.map((column) => (
        <KanbanColumn
          callback={callback}
          key={column.workflow_step_id}
          id={column.workflow_step_id}
          name={column.status}
          stage={column.workflow_step_id}
          projects={projects.filter((project) => {
            console.log("part Checking project:", typeof isGeneric); // Log each project for debugging
            if (isGeneric == "true") {
              console.log(
                "part if",
                project.generic_step_id,
                column.workflow_step_id,
                column.status
              );
              return (
                parseInt(project.generic_step_id, 10) ===
                column.workflow_step_id
              );
            } else {
              // Log the project ID and workflow step ID
              console.log(
                "part else",
                project.card.workflow_step_id,
                column.workflow_step_id
              );
              return (
                parseInt(project.card.workflow_step_id, 10) ===
                column.workflow_step_id
              ); // Include the actual condition
            }
          })}
          // projects={projects.filter(
          //   isGeneric
          //     ? (project) =>
          //         parseInt(project.generic_step_id, 10) ===
          //         column.workflow_step_id
          //     : (project) =>
          //         parseInt(project?.card.workflow_step_id, 10) ===
          //         column.workflow_step_id
          // )}
          onDragEnter={onDragEnter}
          onDragEnd={onDragEnd}
          maxHeight={maxHeight}
        />
      ))}
    </div>
  );
}

export default KanbanContainor;
