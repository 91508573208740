import React, { useEffect } from "react";

import TeamFilterIcon from "../../../../styles/svg/team-filter.svg";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTeams } from "../../../../utils/reactQuery/teams";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllGroupUser,
  setTeamGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import DownArrow from "../../../../styles/svg/downArrow.svg";

function TeamFilter({ isButton = false }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const { teams } = useTeams();
  const userTeam = useSelector((state) => state.users.logInUser.team_id);

  const setTeam = (users) => {
    console.log("users to dispatch team == ", users);
    dispatch(setTeamGroupUser(users));
  };
  const setAllTeam = () => {
    if (groupingUser.length >= teams?.data.data.responseData.length) {
      dispatch(setAllGroupUser([]));
    } else {
      dispatch(setAllGroupUser(teams?.data?.data?.responseData));
    }
  };
  useEffect(() => {
    if (teams) {
      setTeam(
        teams?.data.data.responseData.filter(
          (ele) => ele.team_id == userTeam
        )?.[0]
      );
    }
  }, [teams]);
  return (
    <div className="mr-1 d-flex align-items-center ">
      {isButton ? (
        <Button
          variant="outlined"
          color="primary"
          id="epics-board-dropdown"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<img src={DownArrow} />}
          // className="white-button epics-btn"
          className="filter-btn white-button"
          sx={{
            height: "38px",
            "&:hover": {
              outline: "none",
              backgroundColor: "rgb(240 249 254)",
              border: "1px solid #1b3e61",
            },
          }}
        >
          <p className="header-action-button-text mr-4">All Teams</p>
        </Button>
      ) : (
        <div className="d-flex align-items-center">
          <img
            src={TeamFilterIcon}
            className="mr-2 cursor-pointer"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />
          <p className="text-700-theme-color">All Teams</p>
        </div>
      )}

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        <MenuItem onClick={setAllTeam}>
          <label class="custom-checkbox">
            <input
              type="checkbox"
              // checked={el.user_id == user.user_id}
              checked={
                groupingUser.length >= teams?.data.data.responseData.length
              }
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            <span class="checkmark"></span>
            Select All
          </label>
        </MenuItem>
        {teams?.data.data.responseData.map((team, idx) =>
          team ? (
            <MenuItem
              className="custom-border"
              key={`team-list-${idx}`}
              onClick={() => setTeam(team)}
            >
              <label class="custom-checkbox">
                <input
                  type="checkbox"
                  // checked={el.user_id == user.user_id}
                  checked={groupingUser.some(
                    (element) => element.team == team.team
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                <span class="checkmark"></span>
                {team.team}
              </label>
            </MenuItem>
          ) : (
            ""
          )
        )}
      </Menu>
    </div>
  );
}

export default TeamFilter;
