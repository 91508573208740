import React, { useEffect, useState } from 'react'
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch } from 'react-redux';
import { setDateRange } from '../../../../redux/SearchSlice';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useCustomSearchParams } from '../../../../hooks/useCustomSearchParams';
import moment from 'moment';

function DateRange({ onClose }) {
    const [Olddate, updateParams, removeSearchParam] =
      useCustomSearchParams("date");
  const dispatch = useDispatch();
  const dateRange = useSelector((state) => state.search.dateRange);
  const date = JSON.parse(Olddate);
  
  console.log( date);
  const [state, setState] = useState([
    dateRange
      ? dateRange
      : {
          startDate: date ? new Date(date.startDate) : new Date(),
          endDate: date ? new Date(date.endDate) : new Date(),
          key: "selection",
        },
  ]);



  const handleChange = (item) => {
    setState([item.selection]);
    // dispatch(setDateRange(item.selection));
  };

  const setData = () => {
    console.log("state[0]", state[0]);
    dispatch(setDateRange(state[0]));
    updateParams("date", JSON.stringify(state[0]));
    onClose();
  };

  return (
    <div className="d-grid">
      <DateRangePicker
        onChange={handleChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={state}
        direction="horizontal"
      />
      <div className="d-flex">
        <Button
          onClick={() => {
            dispatch(setDateRange(null));
            onClose();
            removeSearchParam('date')
          }}
          className="reset-date-picker"
        >
          Reset
        </Button>
        <Button
          onClick={setData}
          className="submit-date-picker"
          variant='contained'
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default DateRange