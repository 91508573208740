import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import TextEditor from "../textEditor/textEditor";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { assignCard, updateCard } from "../../../../sevices/apiCards";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { removeHtmlTagsAndEntities } from "../../../../utils/removeHtml";

const modules = {
  toolbar: [
    [],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike"],
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [], // Add color and background color options
    [],
    [],
  ],
};

const CommentForm = ({ cardId, userId, onClose }) => {
  const queryClient = useQueryClient();
  const { id: boardId } = useParams();
  const [comment, setCommet] = useState("");
  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    comment: Yup.string(),
  });

  // Initialize the form with React Hook Form and Yup resolver
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { comment: "" },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: assignCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries(["card", cardId]);
      queryClient.invalidateQueries({
        queryKey: ['card', `${cardId}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });

      onClose();
      toast.success(res.data.message);
    },
    onError: (error) => {
      // console.error("Error assig board", error);
      toast.error(error.response.data.message);
      onClose();
    },
  });

  // Handle form submission
  const onSubmit = (data) => {
    console.log("Form data:", cardId, userId, data);
    // Add your form submission logic here
    mutate({
      card_id: cardId,
      data: { assigned_to_user_id: userId, comment: data.comment },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div style={{marginTop: '5px', height: '208px'}}>
        <ReactQuill
          value={methods.getValues("comment")}
          onChange={(value) =>
            methods.setValue(
              "comment",
              removeHtmlTagsAndEntities(value).length === 0 ? "" : value
            )
          }
          modules={modules}
          style={{ height: "170px" }}
        />
      </div>
      <Typography className={"comments-react-quill"}>
        {methods.formState.errors.comment?.message}
      </Typography>
      <Stack
        sx={{
          justifyContent: "end",
          marginTop: "20px",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          disabled={isPending}
          sx={{
            bgcolor: "#1B3E61",
            fontSize: "16px",
            padding: "10px 16px",
            fontWeight: "600",
            textTransform: "capitalize",
            minWidth: "130px",
            alignSelf: "end",
          }}
          onClick={(e) => e.stopPropagation()}
          // disabled={loading}
        >
          Send
        </Button>
      </Stack>
    </form>
  );
};

export default CommentForm;
