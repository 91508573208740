import { Button, Divider, MenuItem } from '@mui/material';
import React from 'react'

import { useCustomSearchParams } from '../../../../hooks/useCustomSearchParams';
import DownArrow from "../../../../styles/svg/downArrow.svg";
import CustomMenu from '../../../../components/Menu';

function FilterStatus() {
     const [anchorEl, setAnchorEl] = React.useState(null);
     const [userFilter, updateParams, removeSearchParam] =
       useCustomSearchParams("users-status");

     const handleClick = (event) => {
       setAnchorEl(event.currentTarget);
     };
     const handleClose = () => {
       setAnchorEl(null);
     };
     const open = Boolean(anchorEl);
  return (
    <div className='mr-2'>
      <Button
        variant="outlined"
        color="primary"
        className="filter-btn white-button"
        onClick={handleClick}
        endIcon={<img src={DownArrow} />}
        sx={{
          height: "38px",
          width:'148px',
          justifyContent:'space-between',
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}
      >
        <p className="mr-4 capitalize">{userFilter || "Status"}</p>
      </Button>
      <CustomMenu open={open} handleClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("users-status", "all")}
        >
          All
        </MenuItem>
        <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("users-status", "active")}
        >
          Active
        </MenuItem>
        <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("users-status", "inactive")}
        >
          In-Active
        </MenuItem>
      </CustomMenu>
    </div>
  );
}

export default FilterStatus