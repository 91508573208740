import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useParams, useSearchParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import CheckBox from "../../../../styles/svg/id-icon.svg";
import AssignCard from "./AssignCard";
import EditTitle from "./EditTitle";
import { isAdmin } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import CustomizedProgressBars from "../progressBar/ProgressBar";
import DeleteCard from "./DeleteCard";
import checkMark from "../../../../styles/svg/checkMark.svg";
import CustomDateFormatter from "../dateTimeFormateConverter/dateTimeFormateConverter";

function KanbanCard({ project, onDragEnd, boardName, index, id, status }) {
  const { id: boardId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  // const searchParams = new URLSearchParams(useSearchParams());
  // const setSearchParams = useSearchParams();
  const [collapsed, setCollapsed] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [deleteCardHoverIndex, setDeleteCardHoverIndex] = useState(null);
  const currentUser = useSelector((state) => state.users.logInUser);
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", boardId]);
  //   const queryClient = useQueryClient();
  //   const cachedData = queryClient.getQueryData({ queryKey: ["todos"] });
  // console.log("project", project);
  const tooltipName = `${project.card.assigned_to_user?.first_name || ""} ${
    project.card.assigned_to_user?.last_name || ""
  }`;
  const firstName = project.card.assigned_to_user?.first_name[0];
  const lastName = project.card.assigned_to_user?.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  //project.assigned_to_user
  // console.log("projectssss", project);
  const handleDetails = (e) => {
    // e.stopPropagation();
    // setCardId(project.card_id);
    // console.log("searchParams",project?.card_id);
    // console.log("searchParams",project);
    // setShowDetails(true);
    // setSearchParams({'card_id': project?.card_id })
    updateSearchParams("card_id", project?.card.card_id);
  };

  console.log("projecttt", project.assigned_user_last_seen, project.card.title);

  const accessForDeleteCard =
    isAdmin() ||
    currentUser?.user_id == cachedData?.data?.data.responseData?.owner_user_id;

  const accessDragCard =
    currentUser?.user_id == project?.card.assigned_to_user_id ||
    currentUser?.user_id == project?.card.reporter ||
    currentUser?.user_id ==
      cachedData?.data?.data.responseData?.owner_user_id ||
    isAdmin();

  const accessForAssignOrEditTitle =
    currentUser?.user_id == project?.card.reporter ||
    currentUser?.user_id ==
      cachedData?.data?.data.responseData?.owner_user_id ||
    isAdmin();

  const showEditButton = () => {
    if (accessForAssignOrEditTitle) setHoveredIndex(true);
  };

  const showDeleteButton = () => {
    if (accessForDeleteCard) {
      setDeleteCardHoverIndex(true);
    }
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  console.log("seen", project);

  return (
    <Draggable
      key={id.toString()}
      draggableId={project.card.card_id.toString()}
      index={index}
      isDragDisabled={!accessDragCard}
    >
      {(provided, snapshot) => (
        <>
          <div
            className="card-style "
            // draggable={true}
            // onDragEnd={(e) => {
            //   onDragEnd(e, project);
            // }}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={handleDetails}
            //  onClick={()=>{setSearchParams('card_id', project?.card_id )
            //  handleDetails()
            // }}
            onMouseEnter={() => {
              showEditButton();
              showDeleteButton();
            }}
            onMouseLeave={() => {
              setHoveredIndex(null);
              setDeleteCardHoverIndex(null);
            }}
          >
            <div className="d-flex">
              <EditTitle project={project} hoveredIndex={hoveredIndex} />
              {deleteCardHoverIndex && (
                <DeleteCard
                  cardId={project?.card.card_id}
                  boardId={boardId}
                  kanbanCardDelete={true}
                />
              )}
            </div>
            {collapsed ? null : (
              <div>
                <strong>Description: </strong>
                {project.card.description}
                <br />
              </div>
            )}

            <div className="d-flex">
              {project.card.labels?.[0] != "" && (
                <div className="d-flex">
                  {project.labels.map((el) => (
                    <div className="card-level-tag mr-1">
                      <p className="card-level-tag-text">{el}</p>
                    </div>
                  ))}
                </div>
              )}

              {project.card?.epic && (
                <div className="d-flex">
                  {/* {project.labels.map((el) => ( */}
                  <div className="card-epic-tag mr-1">
                    <p className="card-epic-tag-text">
                      {project.card?.epic?.epic_name}
                    </p>
                  </div>
                  {/* ))} */}
                </div>
              )}
            </div>

            <div className="card-bottom d-flex align-items-center justify-content-space-between">
              <div>
                <p className="d-flex align-items-center">
                  <img src={CheckBox} className="mr-1" />{" "}
                  <p className="card-id-number">
                    {boardName.key}-{project.card.card_id}
                  </p>
                </p>
              </div>
              <div className="avatar-container">
                {project.assigned_user_last_seen && (
                  <Tooltip
                    title={
                      <CustomDateFormatter
                        date={project.assigned_user_last_seen}
                      />
                    }
                    arrow
                  >
                    {/* <IconButton> */}
                    <img
                      src={checkMark}
                      alt="checkMark"
                      className="cursor-pointer checkMark-position"
                    />
                    {/* </IconButton> */}
                  </Tooltip>
                )}
                <div className="ml-1">
                  <AssignCard
                    tooltipName={tooltipName}
                    project={project.card}
                    name={name}
                    accessForAssign={accessForAssignOrEditTitle}
                    cardId={project?.card.card_id}
                  />
                </div>
              </div>
            </div>
            <CustomizedProgressBars
              allocatedHours={project.card?.allocated_hours}
              hoursSpent={
                project?.total_hours_spent ? project?.total_hours_spent : 0
              }
              showOnCardOnBoard={true}
            />
          </div>
        </>
      )}
    </Draggable>
  );
}

export default KanbanCard;
