import {
  Badge,
  Box,
  Grid,
  Typography,
  styled,
  Avatar as Muiavatar,
  Divider,
} from "@mui/material";
import React from "react";
import "../../details/detailGrid/history/history.scss";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import arrow from "../../../../../styles/svg/arrow.svg";
import HistoryIcon from "../../../../../styles/svg/history-icon.svg";
import Avatar from "../../../../../components/Avatar";
import CustomDateFormatter from "../../dateTimeFormateConverter/dateTimeFormateConverter";
import moment from "moment";

const SmallAvatar = styled(Muiavatar)(({ theme }) => ({
  width: 16,
  height: 16,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function FeedAssign({ history, showIcon = false, boardName }) {
  // console.log("HISTORYTIME", history.at_time);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        className="worklog-item d-flex justify-content-space-between"
      >
        <Grid item xs={9} className="feed-assignee">
          <div className="d-flex w-100">
            {showIcon ? (
              <Badge
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                badgeContent={
                  <SmallAvatar alt="History Icon" src={HistoryIcon} />
                }
              >
                <Avatar
                  user_image={history?.response_object?.user_object?.user_image}
                  title={history?.response_object?.user_object?.username}
                  className="user-avtar-dropdown user-avatar mr-8"
                  style={{
                    height: "32px",
                    width: "32px",
                    ...(!hasImage(
                      history?.response_object?.user_object?.user_image
                    ) && {
                      bgcolor:
                        history?.response_object?.user_object?.profile_color
                          ?.background,
                      color:
                        history?.response_object?.user_object?.profile_color
                          ?.background.text,
                    }),
                  }}
                >
                  {createAvtarName(
                    history?.response_object?.user_object?.username || ""
                  )}
                </Avatar>
              </Badge>
            ) : (
              <Avatar
                user_image={history?.response_object?.user_object?.user_image}
                title={history?.response_object?.user_object?.username}
                className="user-avtar-dropdown user-avatar mr-8"
                style={{
                  height: "32px",
                  width: "32px",
                  ...(!hasImage(
                    history?.response_object?.user_object?.user_image
                  ) && {
                    bgcolor:
                      history?.response_object?.user_object?.profile_color
                        ?.background,
                    color:
                      history?.response_object?.user_object?.profile_color
                        ?.background.text,
                  }),
                }}
              >
                {createAvtarName(
                  history?.response_object?.user_object?.username || ""
                )}
              </Avatar>
            )}

            <div className="w-100">
              <div className="d-flex justify-content-space-between">
                <div
                  className="worklog-worklog comment-content"
                  dangerouslySetInnerHTML={{ __html: history.history_text }}
                />
                {/* Remove date from here */}
              </div>

              {history.response_object && (
                <>
                  {history.response_object?.event_type === "card_assign" && (
                    <div className="d-flex align-items-center history-heading">
                      {history.response_object?.old_assignee && (
                        <>
                          <Avatar
                            user_image={
                              history.response_object?.old_assignee?.user_image
                            }
                            className={"history-avatar mr-1"}
                            title={
                              history?.response_object?.old_assignee?.username
                            }
                            style={{
                              ...(!hasImage(
                                history.response_object?.old_assignee
                                  ?.user_image
                              ) && {
                                bgcolor:
                                  history?.response_object?.old_assignee
                                    ?.profile_color?.background,
                                color:
                                  history?.response_object?.old_assignee
                                    ?.profile_color?.background.text,
                              }),
                            }}
                          >
                            {createAvtarName(
                              history.response_object?.old_assignee?.username
                            )}
                          </Avatar>
                          <p className="mr-4">
                            {history.response_object?.old_assignee?.username}{" "}
                            <img className="ml-4" src={arrow} alt="" />
                          </p>
                        </>
                      )}
                      {history.response_object?.new_assignee && (
                        <>
                          <Avatar
                            user_image={
                              history.response_object?.new_assignee?.user_image
                            }
                            className={"history-avatar mr-1"}
                            title={
                              history?.response_object?.new_assignee?.username
                            }
                            style={{
                              ...(!hasImage(
                                history.response_object?.new_assignee
                                  ?.user_image
                              ) && {
                                bgcolor:
                                  history?.response_object?.new_assignee
                                    ?.profile_color?.background,
                                color:
                                  history?.response_object?.new_assignee
                                    ?.profile_color?.background.text,
                              }),
                            }}
                          >
                            {createAvtarName(
                              history.response_object?.new_assignee?.username
                            )}
                          </Avatar>
                          <p>
                            {history.response_object?.new_assignee?.username}
                          </p>
                        </>
                      )}
                    </div>
                  )}
                  {history.response_object?.event_type === "change_status" && (
                    <div className="d-flex align-items-center history-heading">
                      <div className="old-status-box">
                        <p className="old-status">
                          {history.response_object?.old_status}
                        </p>
                      </div>
                      <img className="arrow-img" src={arrow} alt="" />
                      <div className="new-status-box">
                        <p className="new-status">
                          {history.response_object?.new_status}
                        </p>
                      </div>
                    </div>
                  )}
                  {history.response_object?.event_type === "change_board" && (
                    <div className="d-flex align-items-center history-heading">
                      <div className="old-status-box">
                        <p className="old-status">
                          {history.response_object?.old_board}
                        </p>
                      </div>
                      <img className="arrow-img" src={arrow} alt="" />
                      <div className="new-status-box">
                        <p className="new-status">
                          {history.response_object?.new_board}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          className="d-flex align-items-center justify-content-end"
        >
          <p className="worklog-date-time comment-date comment">Updated on </p>
          <Typography className="worklog-date-time comment-date">
            {/* <CustomDateFormatter date={history.at_time} /> */}
            {moment
              .utc(history.at_time)
              .local()
              .format("MMMM D, YYYY [at] h:mm A")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FeedAssign;
