import React from "react";
// import ShowComment from "../commentHistory/ShowComment";
import FeedHistory from "./FeedHistory";
import FeedLabelHistory from "./FeedLabelHistory";
import FeedGitHistory from "./FeedGitHistory";
import ListFeedCard from "../feedCard/ListFeedCard";

const ListFeedHistory = ({ history, boardName }) => {
  return (
    <>
      {history.history_type == "Comment" && (
        <FeedHistory history={history} boardName={boardName} />
      )}
      {history.history_type == "WorkLog" && (
        <FeedHistory history={history} boardName={boardName} />
      )}
      {history.history_type == "Attachments" && (
        <FeedHistory history={history} />
      )}
      {history.history_type == "Watchlist" && (
        <FeedHistory history={history} boardName={boardName} />
      )}
      {history.history_type == "Title" && (
        <FeedHistory history={history} boardName={boardName} />
      )}
      {history.history_type == "Label" && (
        <FeedLabelHistory history={history} boardName={boardName} />
      )}
      {history.history_type == "Epic" && (
        <FeedLabelHistory history={history} boardName={boardName} />
      )}
      {history.history_type == "git_event" && (
        <FeedGitHistory history={history} />
      )}
      {history.history_type == "Card" && (
        <ListFeedCard card={history} boardName={boardName} />
      )}
    </>
  );
};

export default ListFeedHistory;
