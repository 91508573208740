import React from 'react'
import FilterStatus from './FilterStatus';
import FilterUserType from './FilterUserType';
import InviteUser from './InviteUser';
import CreateTeam from './CreateTeam';

function Header() {
  return (
    <div className="d-flex justify-content-space-between mb-4">
      <p className="admin-header-heading">User Management</p>
      <div className="d-flex align-items-center ">
        <div className="d-flex align-items-center mr-2">
          <p className="mr-3 filter-heading">Filter by:</p>
          <FilterUserType/>
          <FilterStatus />
          <InviteUser/>
          <CreateTeam/>
        </div>
        <div>
          {/* <Button
            onClick={handleClickOpen}
            className="theme-bg-color"
            variant="contained"
            startIcon={<img src={Vector} />}
          >
            Create Board
          </Button> */}
          
        </div>
      </div>
    </div>
  );
}

export default Header