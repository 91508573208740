import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FormDialog({
  open,
  children,
  handleClose,
  callback,
  loading,
  title,
  btnTitle,
  btnColor = "#1B3E61",
  width
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        onClose={handleClose}
        onClick={(e)=>e.stopPropagation()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          height: "90vh",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            maxWidth: width ? width :"470px",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "rgba(242, 246, 254, 1)" }}>
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
