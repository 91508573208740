import React from "react";
import Avatar from "../../../../../components/Avatar";
import { Grid, Tooltip } from "@mui/material";
import moment from "moment";
import CustomizedProgressBars from "../../../../board/components/progressBar/ProgressBar";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import CustomDateFormatter from "../../../../board/components/dateTimeFormateConverter/dateTimeFormateConverter";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";

function FeedHistory({ history, boardName }) {
  const navigate = useNavigate();
  const [data, updateSearchParams] = useCustomSearchParams("card_id");

  const openBoard = () => {
    navigate(`/board/${boardName?.data?.data?.responseData?.board_id}`);
  };

  return (
    <>
      {history ? (
        <Grid
          container
          spacing={2}
          className="worklog-item d-flex justify-content-space-between"
        >
          <Grid item xs={9}>
            <div className="d-flex w-100">
              <Avatar
                // key={`user-avtar-${el.user_id}`}
                username={`${history.response_object.user_object.username}`}
                user_image={history.response_object.user_object.user_image}
                title={`${history.response_object.user_object.username}`}
                className="user-avtar-dropdown user-avatar mr-8"
                style={
                  !hasImage(history.response_object.user_object.user_image) && {
                    bgcolor:
                      history.response_object.user_object.user_profile_color
                        .background,
                    color:
                      history.response_object.user_object.user_profile_color
                        .text,
                  }
                }
              >
                {createAvtarName(
                  `${history.response_object.user_object.username}` || ""
                )}
              </Avatar>
              <div className="w-100">
                <div className="d-flex justify-content-space-between ">
                  <div className="d-flex align-items-center">
                    <Tooltip
                      title={boardName?.data?.data?.responseData?.board_name}
                    >
                      <a
                        className="board-anquor cursor-pointer"
                        onClick={openBoard}
                      >
                        {`${boardName?.data?.data?.responseData?.key}`.toUpperCase()}
                      </a>
                    </Tooltip>
                    -
                    <a
                      className="board-anquor cursor-pointer mr-1"
                      onClick={() => {
                        updateSearchParams("card_id", history.card_id);
                      }}
                    >
                      {history.card_id}:
                    </a>
                    <p className="worklog-title mr-2">
                      {history.response_object.user_object.username}
                    </p>
                  </div>
                  {/* <p className="hours">{worklog.hours_spent} Hour</p> */}
                </div>
                <p
                  className="worklog-worklog comment-content"
                  dangerouslySetInnerHTML={{
                    __html: history.history_text,
                  }}
                ></p>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="right-details d-flex">
              <p className="worklog-date-time comment-date comment">
                Updated on{" "}
              </p>
              <p className="worklog-date-time comment-date">
                {/* {!sameDay && <img src={lateIcon} className="late-icon" />} */}

                {moment
                  .utc(history.at_time)
                  .local()
                  .format("MMMM D, YYYY [at] h:mm A")}
              </p>
            </div>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default FeedHistory;
