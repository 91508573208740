import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { truncateText } from "../utils/helpers";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({
  register,
  getValues,
  watch,
  fileChange,
  fileName,
}) {
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  // const handleSelectedFile = (e) => {
  //   setSelectedFiles(
  //     // Array.from(e.target.files).map((file) => ({ name: file.name }))
  //     Array.from(e.target.files)
  //   );
  // };

  // React.useEffect(() => {
  // console.log("selectedFiles", selectedFiles);
  // }, [selectedFiles]);

  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      className="white-button file-upload-button"
      sx={{
        width: "100%",
        height: "56px",
        boxShadow: "none",
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "flex-start",
        // marginBottom: "10px",
      }}
      startIcon={!fileName && <CloudUploadIcon />}
    >
      {!fileName && selectedFiles.name && truncateText(selectedFiles.name, 12)}
      {!fileName && !selectedFiles.name
        ? "Upload file"
        : truncateText(fileName, 12)}
      <VisuallyHiddenInput
        multiple
        type="file"
        {...register("file")}
        onChange={(e) => {
          setSelectedFiles(e.target.files);
          fileChange(e);
        }}
      />
    </Button>
  );
}
