import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
// import Avatar from "../../../../../../components/Avatar";
import Avatar from "../../../../../../components/Avatar";
import "./addComment.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addComment } from "../../../../../../sevices/apiComment";
import toast from "react-hot-toast";
import { localStorageInstance } from "../../../../../../utils/localStorage";
import { useSelector } from "react-redux";
import TextEditor from "../../../textEditor/textEditor";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
export default function BasicTextFields({ cardId }) {
  const [comment, setComment] = useState("");
  // const [loading, setLoading] = useState(false)
  // const [apiCall, setApiCall] = useState(true)
  const { id } = useParams();
  const [showEditor, setShowEditor] = useState(false);
  const queryClient = useQueryClient();
  const user = useSelector((state) => state.users.logInUser);
  console.log("image", user);
  const firstName = user.first_name[0];
  const lastName = user.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const tooltipName = `${user.first_name || ""} ${user.last_name || ""}`;
  const { mutate: addCommentmutate } = useMutation({
    mutationFn: addComment,
    onSuccess: (res) => {
      console.log("Invalidating queries with id:", id);
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      // setComment("");
      queryClient.invalidateQueries({
        queryKey: [`comment`, cardId],
      });
      // queryClient.invalidateQueries([`feeds`, id]);
      // queryClient.invalidateQueries([`commentBoard`, id]);
      queryClient.invalidateQueries({
        queryKey: [`feeds`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`commentBoard`, id],
      });
      // setApiCall(true)
      // setLoading(false);
      // console.log(res);
      //    reset();
      setComment("");
      setShowEditor(false);
      toast.success(res.data.message);

      // setOpenDialog(false);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      toast.error(error.response.data.message);
      // setLoading(false);
      // setOpenDialog(false);
      // setApiCall(true)
    },
  });

  const handleChange = (e) => {
    const comt = e.target.value;
    setComment(comt);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // if(comment != ''){
      const trimmedComment = comment.trim();
      if (trimmedComment !== "") {
        // console.log("COMMENT??????",comment);
        addCommentmutate({
          card_id: cardId,
          comment: comment,
        });
      }
    }
  };

  const handleCancel = () => {
    setShowEditor(false);
  };
  const handleSave = (e) => {
    // e.preventDefault();
    // if(comment != ''){

    const trimmedComment = comment.trim();
    if (trimmedComment !== "") {
      // console.log("COMMENT??????",comment);
      addCommentmutate({
        card_id: cardId,
        comment: comment,
      });
      setShowEditor(false);
    }
  };

  return (
    <>
      <div className="d-flex  add-comment">
        <div>
          <Avatar
            title={tooltipName}
            className={" header-avatar add-comment-avtar"}
            user_image={user.user_image}
            style={
              !user.user_image && {
                bgcolor: user?.profile_color?.background,
                color: user?.profile_color?.text,
              }
            }
          >
            {name}
          </Avatar>
        </div>
        {showEditor && (
          <div className="comment-textEditor">
            <TextEditor
              description={null}
              setTextContent={setComment}
              comment={true}
            />
            <div className="text-editor-btn-div">
              <Button
                onClick={handleSave}
                className="text-editor-btn1"
                variant="contained"
              >
                <div className="text-editor-btn-content">Save</div>
              </Button>
              <Button
                onClick={handleCancel}
                className="text-editor-btn2"
                variant="outlined"
              >
                <div className="text-editor-btn-content">Cancel</div>
              </Button>
            </div>
          </div>
        )}

        {/* <Box
          component="form"
          sx={{
            "& > :not(style)": {
              marginLeft: "10px",
              width: "695px",
              height: "50px",
            },
          }}
          noValidate
          autoComplete="off"
        >  
           {!showEditor &&<TextField
            className="add-comment-field"
            id="outlined-basic"
            label="Add a comment..."
            variant="outlined"
            value={comment}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onClick={()=>{setShowEditor(true)}}
            // onBlur={handeBlur}
          />}
        </Box> */}
        {!showEditor && (
          <div
            className="add-comment-field"
            onClick={() => {
              setShowEditor(true);
            }}
          >
            <p>Add a comment...</p>
          </div>
        )}
      </div>
    </>
  );
}
