import React from "react";
import { Button } from "@mui/material";

import CreateBoard from "../../../dashboard/components/CreateBoard";
import DialogCreateBoard from "../../../dashboard/components/DialogCreateBoard";
import Vector from "../../../../styles/svg/create-board.svg";
import FilterArchive from "./FilterArchive";

function Header() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="d-flex justify-content-space-between mb-4">
      <p className="admin-header-heading">Board Management</p>
      <div className="d-flex align-items-center ">
        <div className="d-flex align-items-center mr-2">
          <p className="mr-1 filter-heading">Filter by Status:</p>
          <FilterArchive />
        </div>
        <div>
          <Button
            onClick={handleClickOpen}
            className="theme-bg-color capitalize"
            variant="contained"
            startIcon={<img src={Vector} />}
          >
            Create Board
          </Button>
          {open && <DialogCreateBoard open={open} handleClose={handleClose} />}
        </div>
      </div>
    </div>
  );
}

export default Header;
