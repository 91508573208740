import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./redux/SearchSlice";
import boardListReducer from "./redux/boardListSlice";
import usersReducer from "./redux/usersSlice";
import searchEpicReducer from "./redux/EpicSearchSlice";
import ActiveBoardSliceReducer from "./redux/ActiveBoardSlice";
import TaskGroupSliceReducer from "./redux/TaskGroupingSlice";
import checkboxReducer from "./redux/CheckboxSclice";
// import { conditionMiddleware } from "./redux/SearchSlice";

const store = configureStore({
  reducer: {
    search: searchReducer,
    boardList: boardListReducer,
    users: usersReducer,
    epic: searchEpicReducer,
    activeBoard: ActiveBoardSliceReducer,
    groupingUser: TaskGroupSliceReducer,
    checkbox: checkboxReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(conditionMiddleware),
});

export default store;
