import React from "react";
import { useSelector } from "react-redux";

import UsersKanban from './UsersKanban'
import Team from "./Team";
import { useSearchParams } from "react-router-dom";

function GroupingBoards() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isChecked = searchParams.get("switch");
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
 console.log("isChecked", groupingUser);
  return (
    <div>
      {isChecked == "true" ? (
        <Team groupingUser={groupingUser} />
      ) : (
        <UsersKanban users={groupingUser.map((item) => item.users).flat()} />
      )}
      
    </div>
  );
}

export default GroupingBoards;
