import React from 'react'
import WorkflowDateRangeFilter from './DateRangeFilter'
import DateRangeFilterButton from './DateRangeFilterButton'
const Header = () => {
  return (
    <>

      <div className="report-header">
              <p className="report-header-name">No Status Report</p>
              <div className="date-filter">
                <p className="filterBy">Filter By Date:</p>
                <DateRangeFilterButton/>
              </div>
              
      </div>
      
    </>
  )
}

export default Header