import React from "react";
// import Avatar from "../../../../components/Avatar";
import Avatar from "../../../../../components/Avatar";
// import { createAvtarName, hasImage } from "../../../../utils/helpers";
import { Grid, Tooltip } from "@mui/material";
import moment from "moment";
// import CustomizedProgressBars from './ProgressBar';
// import CustomizedProgressBars from "../../../board/components/progressBar/ProgressBar";
import CustomizedProgressBars from "../../../../board/components/progressBar/ProgressBar";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import CustomDateFormatter from "../../../../board/components/dateTimeFormateConverter/dateTimeFormateConverter";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import OpenCardDetail from "../../Kanban/openCardDetail";

// import lateIcon from "../../../../styles/svg/warning-icon.svg";

function ShowComment({ comment, boardName }) {
  const navigate = useNavigate();
  const [data, updateSearchParams] = useCustomSearchParams("card_id");

  const openBoard = () => {
    navigate(`/board/${boardName?.data?.data?.responseData?.board_id}`);
  };

  const localCreatedAt = moment.utc(comment.created_at).local();
  // const localDateTime = moment.utc(worklog.cardlog.date_time).local();

  // Function to check if two moments are on the same day
  // function isSameDay(moment1, moment2) {
  //   return moment1.isSame(moment2, "day");
  // }

  // Compare the dates
  // const sameDay = isSameDay(localCreatedAt, localDateTime);

  return (
    <>
      <OpenCardDetail />
      {comment ? (
        <Grid
          container
          spacing={2}
          className="worklog-item d-flex justify-content-space-between"
        >
          <Grid item xs={9}>
            <div className="d-flex w-100">
              <Avatar
                // key={`user-avtar-${el.user_id}`}
                username={`${comment.username}`}
                user_image={comment.user_image}
                title={`${comment.username}`}
                className="user-avtar-dropdown user-avatar mr-8"
                style={
                  !hasImage(comment.user_image) && {
                    bgcolor: comment.user_profile_color.background,
                    color: comment.user_profile_color.text,
                  }
                }
              >
                {createAvtarName(`${comment.username}` || "")}
              </Avatar>
              <div className="w-100">
                <div className="d-flex justify-content-space-between ">
                  <div className="d-flex align-items-center">
                    <Tooltip
                      title={boardName?.data?.data?.responseData?.board_name}
                    >
                      <a
                        className="board-anquor cursor-pointer"
                        onClick={openBoard}
                      >
                        {`${boardName?.data?.data?.responseData?.key}`.toUpperCase()}
                      </a>
                    </Tooltip>
                    -
                    <a
                      className="board-anquor cursor-pointer mr-1"
                      onClick={() => {
                        updateSearchParams("card_id", comment.card_id);
                      }}
                    >
                      {comment.card_id}:
                    </a>
                    <p className="worklog-title mr-2">{comment.username}</p>
                    {/* {worklog.epic_name && ( */}
                    {/* <div className="card-epic-tag mr-1">
                      <p className="card-epic-tag-text">milestone</p>
                    </div> */}
                    {/* )} */}
                  </div>
                  {/* <p className="hours">{worklog.hours_spent} Hour</p> */}
                </div>
                <p
                  className="worklog-worklog comment-content"
                  dangerouslySetInnerHTML={{
                    __html: comment.comment,
                  }}
                ></p>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="right-details d-flex">
              {/* <div className="d-flex justify-content-end">
                <p className="worklog-status">open</p>
              </div> */}
              <p className="worklog-date-time comment-date comment">
                Commented on{" "}
              </p>
              <p className="worklog-date-time comment-date">
                {/* {!sameDay && <img src={lateIcon} className="late-icon" />} */}

                {moment
                  .utc(comment.comment_time)
                  .local()
                  .format("MMMM D, YYYY [at] h:mm A")}
              </p>
              {/* <CustomizedProgressBars
                allocatedHours={"15"}
                hoursSpent={worklog.total_hour_spent}
              /> */}
              {/* <p className="created-date-worklog">
                {`Created ${moment
                  .utc(worklog.created_at)
                  .local()
                  .format("MMMM D, YYYY [at] h:mm A")}`}
              </p> */}
            </div>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default ShowComment;
