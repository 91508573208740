import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import './textEditor.scss'
// import 'quill/dist/quill.snow.css'; // Import Quill styles
{/* <script src="https://cdn.quilljs.com/1.3.6/quill.js"></script> */}
const TextEditor = ({description ,setTextContent, comment=false}) => {
    const editorRef = useRef(null);
     
    useEffect(() => {
        const options = {
          debug: 'info',
          modules: {
            toolbar: true,
          },
          placeholder: comment ? 'Add Comment...' : 'Add Description...' ,
          theme: 'snow',
        };
        const cleanedText = description && description.replace(/(?<!\n)\n(?!\n)/g, ''); 
        const quill = new Quill(editorRef.current, options);

        quill.on('text-change', () => {

        


          const typedText = quill.root.innerHTML;
    
          setTextContent(typedText);
         
      });

      
        quill.root.innerHTML = cleanedText
  
      
        return () => {
          quill.off('text-change');
          quill.off('selection-change');
          quill.disable();
        };
      }, [description]);
    return <div id={comment ? "commentEditor" : "editor"}  ref={editorRef}></div>;
}

export default TextEditor