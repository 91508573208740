import React, { useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, Select } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";
import User from "./User";
import {
  changeGroupExpand,
  setAllGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import GroupUser from "../../../../styles/svg/Team-group.svg";
import Status from "./Status";
import TeamFilter from "./TeamFilter";
import GlobalSearch from "../../../../components/Search";
import { searchCard } from "../../../../redux/SearchSlice";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useUserWorkflowList } from "../../../../utils/reactQuery/workflows";
import { useSetMultipleParams } from "../../../../hooks/useSetMultipleParams";

function Header() {
  const dispatch = useDispatch();
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const [inputValue, setInputValue] = useState("");
  console.log("groupingUser", groupingUser);
  const [workflowId, setWorkflowId] = useCustomSearchParams("workflow_id");
  const [setMultipleSearchParams] = useSetMultipleParams();
  const { userWorkflows } = useUserWorkflowList();
  const [genericId, setGenericId] = useState(false);

  useEffect(() => {
    console.log(
      "workflows for user list == ",
      userWorkflows?.data?.data?.responseData[0]?.workflow_id
    );
    if (workflowId && workflowId != "undefined") {
      //eat five star do nothing
    } else {
      setWorkflowId(
        "workflow_id",
        userWorkflows?.data?.data?.responseData[0]?.workflow_id
      );
    }
  }, [userWorkflows]);

  const clearFilters = () => {
    dispatch(setAllGroupUser([]));
  };

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
    }, 300) // 300ms delay
  ).current;

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  const checkGenric = (id) => {
    // console.log("ID", userWorkflows?.data?.data?.responseData);

    const temp = userWorkflows?.data?.data?.responseData.filter((ele) => {
      return id == ele.workflow_id;
    });
    if (temp) {
      const isGeneric = temp[0]?.is_generic;
      setGenericId(isGeneric);

      // Set both workflow_id and generic based on isGeneric
      setMultipleSearchParams([
        { name: "workflow_id", value: id },
        { name: "is_generic", value: isGeneric },
      ]);

      console.log("is generic == temp", temp);
    }
  };

  return (
    <div className="d-flex justify-content-space-between ">
      <p className="board-heading-name">Assigned to Team</p>
      <div className="d-flex align-items-center ">
        <div className="d-flex align-items-center mr-2">
          <p className="filter-text">Select Workflow:</p>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={workflowId}
            sx={{ height: "38px", width: "150px", fontSize: "15px" }}
            onChange={(event) =>
              // setWorkflowId("workflow_id", event.target.value)
              checkGenric(event.target.value)
            }
          >
            {userWorkflows?.data?.data?.responseData?.map((ele, idx) => {
              return (
                <MenuItem value={ele.workflow_id}>{ele.workflow_name}</MenuItem>
              );
            })}
          </Select>
        </div>
        <User user={groupingUser.map((item) => item.users).flat()} />
        <Status />
        <TeamFilter />
        <div style={{ marginLeft: "16px" }}>
          <GlobalSearch
            placeholder={"Search..."}
            searchText={inputValue}
            handleChange={handleChange}
          />
        </div>
        {/* {groupingUser.length > 0 && (
          <Button
            size="small"
            endIcon={<ClearIcon />}
            className="ml-3 capitalize"
            onClick={clearFilters}
          >
            Clear Filter
          </Button>
        )} */}
      </div>
      {/* <div>
        <Button
          variant="contained"
          className="see-worklogs mr-2"
          onClick={() => dispatch(changeGroupExpand(!expandAll))}
        >
          {expandAll ? "Collapse All" : "Expand All"}
        </Button>
        <Button
          variant="contained"
          className="see-worklogs"
          onClick={() => removeSearchParam("tab")}
        >
          Back to Tasks
        </Button>
      </div> */}
    </div>
  );
}

export default Header;
