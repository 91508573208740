import React from 'react'
import Header from './components/Header'
import GroupingBoards from './components/GroupingBoards'

function AllocationGrouping() {
  return (
    <div>
        <Header/>
        <GroupingBoards/>
    </div>
  )
}

export default AllocationGrouping