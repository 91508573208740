import * as React from "react";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { updateComment } from "../../../../../../sevices/apiComment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TextEditor from "../../../textEditor/textEditor";
import './editComment.scss'
export default function EditTextFields({cardId, messg ,comment_id,  setShowEdit}) {
  const queryClient = useQueryClient();
    const [newComment, setNewComment] = useState(messg)
    const handleChange = (e)=>{
      setNewComment(e.target.value)
    }

    // const handleKeyDown = (e)=>{
    //   if(e.key === 'Enter'){
    //     e.preventDefault();
    //     updateCommentMutate({
    //       comment_id : comment_id,
    //       comment : newComment
    //     }
    //     )
    //     setShowEdit(false);
    //   }
      
    // }
    const handleSave = (e)=>{
        updateCommentMutate({
          comment_id : comment_id,
          comment : newComment
        }
        )
        setShowEdit(false);
      }
      
    
  const handleCancel = ()=>{
    setShowEdit(false);
  }

    const { mutate: updateCommentMutate } = useMutation({
      mutationFn: updateComment,
      onSuccess: (res) => {
        queryClient.invalidateQueries({
          queryKey: [`comment`, cardId],
        });
        queryClient.invalidateQueries({
          queryKey: [`history`, cardId],
        });
        // setLoading(false);
        // console.log(res);
        //    reset();
        toast.success(res.data.message);
        // handleClose();
      },
      onError: (error) => {
        console.error("Error Delete Attachments", error);
        toast.error(error.response.data.message);
        // setLoading(false);
        // handleClose();
      },
    });


    // return (
    //   <>
    //   <div className="d-flex align-items-center add-comment">
    //     <Box
    //       component="form"
    //       sx={{
    //         "& > :not(style)": {
    //           marginLeft: "-19px",
    //           width: "707px",
    //           height: "50px",
    //           marginBottom: "10px",
    //           marginTop: "-10px"
    //         },
    //       }}
    //       noValidate
    //       autoComplete="off"
    //     >
    //       <TextField
    //         id="outlined-basic"
    //         label="Edit comment..."
    //         variant="outlined"
    //         value={newComment}
    //          onChange={handleChange}
    //         onKeyDown={handleKeyDown}
    //       />
    //     </Box>
    //   </div>
    // </>
    // );


    return(
      <div className="d-flex align-items-center">
          <div className="edit-comment-textEditor">
           <TextEditor description={messg} setTextContent={setNewComment} comment={true}/>
          <div className="text-editor-btn-div">
          <Button
            onClick={handleSave}
            className="text-editor-btn1"
            variant="contained"
          >
            <div className="text-editor-btn-content">Save</div>
          </Button>
          <Button
            onClick={handleCancel}
            className="text-editor-btn2"
            variant="outlined"
          >
            <div className="text-editor-btn-content">Cancel</div>
          </Button>
        </div>
        </div>
      </div>
    )
    }
