import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import GroupUser from "../../../../styles/svg/Team-group.svg";

function Status() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isChecked = searchParams.get("switch");
  const [status, setStatus] = useState(isChecked);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };
  return (
    <div className="ml-44 mr-44 d-flex align-items center">
      <label className="switch">
        <input
          type="checkbox"
          value={status}
          checked={status}
          onChange={(e) => {
            console.log(e.target);
            setStatus((data) => !data);
            updateSearchParams("switch", !status);
          }}
        />
        <div className="slider"></div>
      </label>
      <img className="ml-1" src={GroupUser} />
    </div>
  );
}

export default Status;
