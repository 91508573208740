import { Button } from '@mui/material';
import React from 'react'
import Download from './Download';
import { useSelector } from 'react-redux';
import SearchBoard from '../../components/Filters/SearchBoard';
import GlobalSearch from '../../../../components/Search';

function Header() {
  const totalHours = useSelector((state) => state.search.totalWorkHours);
  return (
    <div className="d-flex justify-content-space-between mb-4">
      <div className="d-flex">
      <p className="heading-worklog-tab mr-32">Worklogs</p>
      <div className="d-flex">
        <div className="mr-32">
          <p className="total-work">Total Work</p>
          <span className="hours-header">{totalHours} hours</span>
        </div>
        <Download />
      </div>
      </div>
      <GlobalSearch placeholder={"Search..."}/>
    </div>
  );
}

export default Header