import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import addIcon from "../../../../styles/svg/addIcon.svg";
import CreateWorkflow from "./createWorkflowPopup/CreateWorkflow";
const HeaderWorkflow = () => {
  const [showCreateWorkflow, setShowCreateWorkflow] = useState(false);

  return (
    <>
      {showCreateWorkflow && (
        <CreateWorkflow
          open={showCreateWorkflow}
          close={setShowCreateWorkflow}
        />
      )}
      <div className="header-name d-flex justify-content-space-between align-items-center">
        <p>Workflow</p>
        <Button
          onClick={() => setShowCreateWorkflow(true)}
          className="create-new-button"
          sx={{
            backgroundColor: "#1B3E61",
            border: "none",
            padding: "9px 12px",
            textTransform: "none",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "19px",
            boxShadow: "0",
            letterSpacing: "0",
            "&:hover": {
              backgroundColor: "#1B3E61",
              border: "none",
              boxShadow: "0",
              letterSpacing: "0",
            },
          }}
          variant="contained">
          <img src={addIcon} alt="" />
          Create New
        </Button>
      </div>
    </>
  );
};

export default HeaderWorkflow;
