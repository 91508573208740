import React from "react";

import Avatar from "../../../../../components/Avatar";

import { Grid, Tooltip } from "@mui/material";
import moment from "moment";

import CustomizedProgressBars from "../../../../board/components/progressBar/ProgressBar";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import CustomDateFormatter from "../../../../board/components/dateTimeFormateConverter/dateTimeFormateConverter";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import OpenCardDetail from "../../Kanban/openCardDetail";

function ShowWorklog({ worklog, boardName }) {
  const navigate = useNavigate();
  const [data, updateSearchParams] = useCustomSearchParams("card_id");

  const openBoard = () => {
    navigate(`/board/${boardName?.data?.data?.responseData?.board_id}`);
  };

  const localCreatedAt = moment.utc(worklog.created_at).local();

  // console.log("BOARDSS", boardName?.data?.data?.responseData?.board_name);

  return (
    <>
      <OpenCardDetail />
      {worklog ? (
        <Grid
          container
          spacing={2}
          className="worklog-item d-flex justify-content-space-between"
        >
          <Grid item xs={9}>
            <div className="d-flex w-100">
              <Avatar
                // key={`user-avtar-${el.user_id}`}
                username={`${worklog.username}`}
                user_image={worklog.user_image}
                title={`${worklog.username}`}
                className="user-avtar-dropdown user-avatar mr-8"
                style={
                  !hasImage(worklog.user_image) && {
                    bgcolor: worklog.user_profile_color.background,
                    color: worklog.user_profile_color.text,
                  }
                }
              >
                {createAvtarName(`${worklog.username}` || "")}
              </Avatar>
              <div className="w-100">
                <div className="d-flex justify-content-space-between ">
                  <div className="d-flex align-items-center">
                    <Tooltip
                      title={boardName?.data?.data?.responseData?.board_name}
                    >
                      <a
                        className="board-anquor cursor-pointer"
                        onClick={openBoard}
                      >
                        {`${boardName?.data?.data?.responseData?.key}`.toUpperCase()}
                      </a>
                    </Tooltip>
                    -
                    <a
                      className="board-anquor cursor-pointer mr-1"
                      onClick={() => {
                        updateSearchParams("card_id", worklog.card_id);
                      }}
                    >
                      {worklog.card_id}:
                    </a>
                    <p className="worklog-title mr-2">{worklog.username}</p>
                  </div>
                  <p className="hours">{worklog.hour_spent} Hour</p>
                </div>
                <p
                  className="worklog-worklog"
                  dangerouslySetInnerHTML={{
                    __html: worklog.work_performed,
                  }}
                ></p>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="right-details">
              <div className="d-flex justify-content-end">
                <p className="worklog-status">{worklog.workflow_step}</p>
              </div>
              <p className="worklog-date-time">
                {moment
                  .utc(worklog.created_at)
                  .local()
                  .format("MMMM D, YYYY [at] h:mm A")}
              </p>
              <CustomizedProgressBars
                allocatedHours={worklog.allocated_hours}
                hoursSpent={worklog.hour_spent}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default ShowWorklog;
