import React, { useState } from "react";
import DeleteIcon from "../../../../styles/svg/delete.svg";
import { deleteCard } from "../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

const DeleteCard = ({ cardId, boardId, kanbanCardDelete, setShowDetails }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const { mutate: deleteCardMutate } = useMutation({
    mutationFn: deleteCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, boardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      setLoading(false);
      toast.success(res.data.message);
      searchParams.delete("card_id");
      setSearchParams(searchParams);
      setShowDelete(false);
      setShowDetails(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      setShowDelete(false);
    },
  });

  const callback = (e) => {
    e.stopPropagation();
    setLoading(true);
    deleteCardMutate(cardId);
  };

  const handleCardDelete = (e) => {
    e.stopPropagation();
    setShowDelete(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setShowDelete(false);
  };

  return (
    <>
      {showDelete && (
        <ConfirmationDialog
          open={showDelete}
          handleClose={handleClose}
          message="Are you sure you want to delete this item? This action cannot be undone."
          callback={callback}
          loading={loading}
          btnTitle="Delete"
          title="Delete Confirmation"
        />
      )}
      <img
        src={DeleteIcon}
        className={kanbanCardDelete ? "delete-card" : "delete-card-button"}
        onClick={(e) => {
          e.stopPropagation();
          handleCardDelete(e);
        }}
        alt="Delete"
        style={{ height: "fit-content" }}
      />
    </>
  );
};

export default DeleteCard;
