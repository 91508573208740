import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useWorklogBoard } from "../../../../utils/reactQuery/worklog";
import { useFeeds } from "../../../../utils/reactQuery/feeds";
import {
  useBoardName,
  useCommentBoard,
} from "../../../../utils/reactQuery/boards";
import WorklogHistory from "./worklogHistory/WorklogHistory";
import ShowComment from "./commentHistory/ShowComment";
import ListFeedHistory from "./feedHistory/ListFeedHistory";
import Loader from "../../../../components/Loader";
import { useSelector } from "react-redux";
const HistoryItems = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [newFeedData, setNewFeedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { id } = useParams();
  const { boardName } = useBoardName(id);
  const filterUserId = searchParams.get("search");
  const filterdate = searchParams.get("date");
  const searchText = useSelector((state) => state.search.searchText);

  const { worklogBoard } = useWorklogBoard(id);
  const { commentBoard } = useCommentBoard(id);
  const { feeds } = useFeeds(id, 1, 20000);

  useEffect(() => {
    const mergeData = () => {
      setIsLoading(true);
      const mergedData = [];

      if (worklogBoard?.data?.data?.responseData) {
        const worklogData = worklogBoard.data.data.responseData.map((item) => ({
          ...item,
          content: "Worklog_content",
        }));
        mergedData.push(...worklogData);
      }

      if (commentBoard?.data?.data?.responseData) {
        const commentData = commentBoard.data.data.responseData.map((item) => ({
          ...item,
          content: "comment_content",
        }));
        mergedData.push(...commentData);
      }

      if (feeds?.data?.data?.responseData) {
        const feedData = feeds.data.data.responseData.map((item) => ({
          ...item,
          content: "history_content",
        }));
        mergedData.push(...feedData);
      }

      const sortedData = mergedData.sort((a, b) => {
        const aDate = new Date(
          a.created_at || a.comment_time || a.at_time
        ).getTime();
        const bDate = new Date(
          b.created_at || b.comment_time || b.at_time
        ).getTime();

        return bDate - aDate; // Sort in descending order
      });

      setNewFeedData(sortedData);
      setIsLoading(false);
      console.log("Merged Feed Data:", mergedData);
    };
    if (worklogBoard && commentBoard && feeds) {
      mergeData();
    }
  }, [worklogBoard, commentBoard, feeds]);

  useEffect(() => {
    const searchByText = () => {
      if (searchText) {
        const filtered = newFeedData.filter((item) => {
          const searchLower = searchText.toLowerCase();
          return (
            String(item.username).toLowerCase().includes(searchLower) || // Check title
            String(item.work_performed).toLowerCase().includes(searchLower) || // Check content
            String(item.card_id).includes(searchLower) || // Optionally check user_id
            String(item.comment).includes(searchLower) ||
            String(item.history_text).toLowerCase().includes(searchLower) ||
            String(item.history_type).toLowerCase().includes(searchLower)
          );
        });
        setFilteredData(filtered);
      } else {
        setFilteredData(newFeedData);
      }
    };
    searchByText();
  }, [searchText]);

  useEffect(() => {
    const applyDateFilter = () => {
      if (filterdate) {
        const dateRange = JSON.parse(filterdate);
        const startDate = new Date(dateRange.startDate);
        const endDate = new Date(dateRange.endDate);
        // Set startDate to the beginning of the day
        startDate.setUTCHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() + 1);
        // Set endDate to the end of the day
        endDate.setUTCHours(23, 59, 59, 999);
        endDate.setDate(endDate.getDate() + 1);
        const filtered = newFeedData.filter((item) => {
          return (
            (new Date(item.created_at) >= startDate &&
              new Date(item.created_at) <= endDate) ||
            (new Date(item.comment_time) >= startDate &&
              new Date(item.comment_time) <= endDate) ||
            (new Date(item.at_time) >= startDate &&
              new Date(item.at_time) <= endDate)
          );
        });
        setFilteredData(filtered);
      } else {
        setFilteredData(newFeedData);
      }
    };
    applyDateFilter();
  }, [filterdate]);

  useEffect(() => {
    const applyFilter = () => {
      if (filterUserId) {
        const filtered = newFeedData.filter(
          (item) =>
            String(item.user_id) === filterUserId ||
            String(item.author_id) === filterUserId ||
            String(item?.response_object?.user_object?.user_id) === filterUserId
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(newFeedData);
      }
    };

    applyFilter();
  }, [newFeedData, filterUserId]);

  if (isLoading) {
    return (
      <div className="loader-center">
        <Loader color={"black"} />
      </div>
    );
  }
  console.log("FILTEREDDATA>>", filteredData);
  return (
    <>
      {filteredData.map((item) => {
        if (item.content == "Worklog_content") {
          return <WorklogHistory worklog={item} boardName={boardName} />;
        }
        if (item.content == "comment_content") {
          return <ShowComment comment={item} boardName={boardName} />;
        }
        if (item.content == "history_content") {
          // console.log("CARD>>", item.response_object.event_type);
          return <ListFeedHistory history={item} boardName={boardName} />;
        }
      })}
    </>
  );
};

export default HistoryItems;
