import React from "react";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams";
import { useMilestoneHistory } from "../../../utils/reactQuery/epics";
import {
  Badge,
  Box,
  Grid,
  styled,
  Typography,
  Avatar as Muiavatar,
} from "@mui/material";
import HistoryIcon from "../../../styles/svg/history-icon.svg";
import { createAvtarName, hasImage } from "../../../utils/helpers";
import CustomDateFormatter from "../../../pages/board/components/dateTimeFormateConverter/dateTimeFormateConverter";
import arrow from "../../../styles/svg/arrow.svg";
import Avatar from "../../../components/Avatar";

const SmallAvatar = styled(Muiavatar)(({ theme }) => ({
  width: 16,
  height: 16,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function MilestoneHistory({ showIcon = true }) {
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const { history } = useMilestoneHistory(milestone);

  console.log("milestone hoistory == ", history);
  return (
    <div>
      <Typography fontSize={"16px"} fontWeight={700} mt={2}>
        Activity
      </Typography>
      {history?.data?.data?.responseData.map((ele) => {
        return (
          <Box sx={{ flexGrow: 1, px: 2, width: "100%" }}>
            <Grid container spacing={2}>
              <div className="worklog-box" style={{ width: "100%" }}>
                <Grid>
                  {showIcon ? (
                    <Badge
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp" src={HistoryIcon} />
                      }>
                      <Avatar
                        user_image={
                          ele?.response_object?.user_object?.user_image
                        }
                        title={ele?.response_object?.user_object?.username}
                        style={{
                          height: "32px",
                          width: "32px",
                          ...(!hasImage(
                            ele?.response_object?.user_object?.user_image
                          ) && {
                            bgcolor:
                              ele?.response_object?.user_object?.profile_color
                                ?.background,
                            color:
                              ele?.response_object?.user_object?.profile_color
                                ?.background.text,
                          }),
                        }}
                        className={" header-avatar"}>
                        {/* {headShotName || ''} */}
                        {createAvtarName(
                          ele?.response_object?.user_object?.username || ""
                        )}
                      </Avatar>
                    </Badge>
                  ) : (
                    <Avatar
                      user_image={ele?.response_object?.user_object?.user_image}
                      title={ele?.response_object?.user_object?.username}
                      style={{
                        height: "32px",
                        width: "32px",
                        ...(!hasImage(
                          ele?.response_object?.user_object?.user_image
                        ) && {
                          bgcolor:
                            ele?.response_object?.user_object?.profile_color
                              ?.background,
                          color:
                            ele?.response_object?.user_object?.profile_color
                              ?.background.text,
                        }),
                      }}
                      className={" header-avatar"}>
                      {/* {headShotName || ''} */}
                      {createAvtarName(
                        ele?.response_object?.user_object?.username || ""
                      )}
                    </Avatar>
                  )}
                  {/* <Avatar
                title={tooltipName}
                className={" header-avatar worklog-box-img"}
                user_image={data.user_image}
              >
                {name}
              </Avatar> */}
                  {/* <Avatar className="worklog-box-img">{data.user_image}</Avatar> */}
                </Grid>
                <div className="worklog-box-body">
                  <div className="d-flex">
                    <Grid sx={{ width: "100%" }}>
                      {/* <Typography className="worklog-box-name"> */}
                      <div className="d-flex justify-content-space-between">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ele?.history_text,
                          }}></div>
                        <Typography className="worklog-box-date">
                          <div className="worklog-box-date-field">
                            <CustomDateFormatter date={ele.at_time} />
                          </div>
                        </Typography>
                      </div>
                      {/* </Typography> */}

                      <div>
                        {ele.response_object && (
                          <>
                            {ele.response_object?.event_type ==
                              "milestone created test" && (
                              <div className="d-flex align-items-center history-heading">
                                {ele.response_object?.old_assignee && (
                                  <>
                                    <div className=".history-img">
                                      <Avatar
                                        user_image={
                                          ele.response_object?.old_assignee
                                            ?.user_image
                                        }
                                        className={"history-avatar mr-1"}
                                        title={
                                          ele?.response_object?.old_assignee
                                            ?.username
                                        }
                                        style={
                                          !hasImage(
                                            ele.response_object?.old_assignee
                                              ?.user_image
                                          ) && {
                                            bgcolor:
                                              ele?.response_object?.old_assignee
                                                ?.profile_color?.background,
                                            color:
                                              ele?.response_object?.old_assignee
                                                ?.profile_color?.background
                                                .text,
                                          }
                                        }>
                                        {createAvtarName(
                                          ele.response_object?.old_assignee
                                            ?.username
                                        )}
                                      </Avatar>
                                    </div>
                                    <p className="mr-4">
                                      {
                                        ele.response_object?.old_assignee
                                          ?.username
                                      }{" "}
                                      {
                                        <img
                                          className="ml-4"
                                          src={arrow}
                                          alt=""
                                        />
                                      }
                                    </p>
                                  </>
                                )}
                                {ele.response_object?.new_assignee && (
                                  <>
                                    <div>
                                      <Avatar
                                        user_image={
                                          ele.response_object?.new_assignee
                                            ?.user_image
                                        }
                                        className={"history-avatar mr-1"}
                                        title={
                                          ele?.response_object?.new_assignee
                                            ?.username
                                        }
                                        style={
                                          !hasImage(
                                            ele.response_object?.new_assignee
                                              ?.user_image
                                          ) && {
                                            bgcolor:
                                              ele?.response_object?.new_assignee
                                                ?.profile_color?.background,
                                            color:
                                              ele?.response_object?.new_assignee
                                                ?.profile_color?.background
                                                .text,
                                          }
                                        }>
                                        {createAvtarName(
                                          ele.response_object?.new_assignee
                                            ?.username
                                        )}
                                      </Avatar>
                                    </div>
                                    <p>
                                      {
                                        ele.response_object?.new_assignee
                                          ?.username
                                      }
                                    </p>
                                  </>
                                )}
                              </div>
                            )}

                            {ele.response_object?.event_type ==
                            "allocated_hours_update" ? (
                              <div className="d-flex align-items-center history-heading">
                                <div className="old-status-box">
                                  <p className="old-status">
                                    {ele.response_object?.old_allocated_hours}
                                  </p>
                                </div>
                                {
                                  <img
                                    className="arrow-img"
                                    src={arrow}
                                    alt=""
                                  />
                                }
                                <div className="new-status-box">
                                  <p className="new-status">
                                    {
                                      ele.response_object
                                        ?.updated_allcated_hours
                                    }
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center history-heading">
                                <p style={{ marginLeft: "5px" }}>
                                  {ele.response_object?.comment}
                                </p>
                                {/* {<img className="arrow-img" src={arrow} alt="" />}
                            <div className="new-status-box">
                              <p className="new-status">
                                {ele.response_object?.new_status}
                              </p>
                            </div> */}
                              </div>
                            )}

                            {/* )} */}
                            {ele.response_object?.event_type ==
                              "change_board" && (
                              <div className="d-flex align-items-center history-heading">
                                <div className="old-status-box">
                                  <p className="old-status">
                                    {ele.response_object?.old_board}
                                  </p>
                                </div>
                                {
                                  <img
                                    className="arrow-img"
                                    src={arrow}
                                    alt=""
                                  />
                                }
                                <div className="new-status-box">
                                  <p className="new-status">
                                    {ele.response_object?.new_board}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Grid>

                    {/* <Grid>
                  <Typography className="worklog-box-date">
                    <div className="worklog-box-date-field">
                    <CustomDateFormatter date={ele.at_time} />
                    </div>
                  </Typography>
                </Grid> */}
                  </div>
                </div>
              </div>
            </Grid>
          </Box>
        );
      })}
    </div>
  );
}

export default MilestoneHistory;
