import React from "react";
import TextField from "@mui/material/TextField";
import { Draggable } from "react-beautiful-dnd";
import workflowStepdotsIcon from "../../../../../styles/svg/workflowStepdotsIcon.svg";
import adminWorkflowDelete from "../../../../../styles/svg/adminWorkflowDelete.svg";
// import RemoveStep from "../RemoveStep";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteWorkflowStep } from "../../../../../sevices/apiWorkflows";
import toast from "react-hot-toast";
import RemoveStep from "../RemoveStep";
import DraggableField from './DraggableField'
const WorkflowSteps = ({
  setNewWorkflowStepsName,
  setShowSteps,
  defaultStep,
  edit,
  register,
  errors,
  fields,
  remove,
  close,
  control
}) => {
  // console.log("fields",edit)
  const [openRemoveStep, setOpenRemoveStep] = React.useState(false)
  const [currentStep, setCurrentStep] = React.useState()
 

  return (
    <>
    {
      openRemoveStep && <RemoveStep close={close} currentStep={currentStep} fields={fields} showRemoveSteps={openRemoveStep} setShowRemoveSteps={setOpenRemoveStep}/>
    }
      {fields &&
        fields.map((field, index) => (
          <DraggableField field={field} control={control} index={index} remove={remove} edit={edit} setOpenRemoveStep={setOpenRemoveStep} register={register} setCurrentStep={setCurrentStep}/>
        ))}
    </>
  );
};

export default WorkflowSteps;
