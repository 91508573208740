import KanbanBoard from "./KanbanBoard";
// import "./kanban.scss";

function Kanban({ workflowSteps, cards, callback }) {
  const style = {
    paddingTop: "30px",
    // paddingTop: "65px",
  };

  return (
    <div style={style}>
      <KanbanBoard
        workflowSteps={workflowSteps}
        cards={cards}
        callback={callback}
      />
    </div>
  );
}

export default Kanban;
