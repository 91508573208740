import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";

import KanbanCard from "./KanbanCard";
import CreateCard from "./CreateCard";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";

function KanbanColumn({
  name,
  stage,
  projects,
  onDragEnter,
  onDragEnd,
  boardName,
  id,
}) {
  const [mouseIsHovering, setMouseIsHovering] = useState(false);
  const [milestone] = useCustomSearchParams("milestone");
  // console.log("PROJ", projects);
  const generateKanbanCards = () => {
    return projects.map((project, index) => (
      <KanbanCard
        status={name}
        boardName={boardName}
        key={project.card.card_id}
        id={project.card.card_id}
        project={project}
        onDragEnd={onDragEnd}
        index={index}
      />
    ));
  };

  const columnStyle = {
    backgroundColor: mouseIsHovering ? "#f0eeee" : "rgba(242, 244, 246, 1)",
  };

  return (
    <div className="card-column">
      <div className="coloumn-header">
        <h4 className="coloumn-header-h4">
          <p className="coloumn-header-text">{name}</p>
          <span className="coloumn-header-length">{projects.length}</span>
        </h4>
      </div>
      <div
        style={columnStyle}
        className="coloumn-body"
        // onDragEnter={(e) => {
        //   setMouseIsHovering(true);
        //   onDragEnter(e, stage);
        // }}
        // onDragLeave={(e) => setMouseIsHovering(false)}
      >
        <Droppable droppableId={id.toString()}>
          {(provided) => (
            <div
              className="coloumn-all-cards"
              style={{
                height: `calc(94vh - ${milestone ? "240px" : "185px"} )`,
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {generateKanbanCards()}
              <CreateCard stage={stage} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <br />
        {/* <Button size="small">Create New</Button> */}
      </div>
    </div>
  );
}
export default KanbanColumn;
