import React from "react";
import Header from "./components/Header";
import "./worklog.scss";
import Filters from "./components/Filters";
import History from "./components/History";
import { Divider } from "@mui/material";

function Worklog() {
  return (
    <>
      <Header/>
      <Divider/>
      <Filters />
      <History />
    </>
  );
}

export default Worklog;
