import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import NavigationBoards from "./components/Boards";
import "./dashboard.scss";
import { localStorageInstance } from "../../utils/localStorage";
import { useLoginUser } from "../../utils/reactQuery/users";
import Logo from "../../styles/svg/sofmenLogo.svg";
import { user } from "../../redux/usersSlice";
import Profile from "../../components/Header/Profile";

export default function Dashboard() {
  const userId = localStorageInstance.getItem("userId");
  const { data, isLoading } = useLoginUser(userId);
  const dispatch = useDispatch();
  if (data) {
    dispatch(user(data.data.data.responseData));
  }
  return (
    <Box>
      {/* <Header /> */}
      <Box className="wrapper_dashboard">
        <Box className="wrapper_sidebar">
          <div className="logo-container">
            <img src={Logo} className="board-logo" alt="Sofmen" />
          </div>
          <Profile />
          <Divider />
          <NavigationBoards />
        </Box>
        <Box className="wrapper_main_container">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="main-container">
                <Outlet />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
