import React from "react";
import FeedHistory from "../feedHistory/FeedHistory";
import FeedAssign from "./FeedAssign";
import HistoryItem from "../../details/detailGrid/history/HistoryItem";

const ListFeedCard = ({ card, boardName }) => {
  console.log("card.response_object", card);
  return (
    <>
      {card.response_object.event_type == "create_card" && (
        <FeedHistory history={card} boardName={boardName} />
      )}
      {card.response_object.event_type == "change_status" && (
        <FeedAssign history={card} boardName={boardName} />
      )}
      {card.response_object.event_type == "description_update" && (
        <FeedHistory history={card} boardName={boardName} />
      )}
      {card.response_object.event_type == "allocated_hours_update" && (
        <FeedHistory history={card} boardName={boardName} />
      )}
      {card.response_object.event_type == "change_board" && (
        <FeedAssign history={card} boardName={boardName} />
      )}
      {card.response_object.event_type == "card_assign" && (
        <FeedAssign history={card} boardName={boardName} />
      )}
    </>
  );
};

export default ListFeedCard;
