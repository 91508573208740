import { Button } from "@mui/material";
import React from "react";

import AddIcon from "@mui/icons-material/Add";
import FormDialog from "../../../../components/Dialog/FormDialog";
import InviteUserForm from "./InviteUserForm";
import CreateTeamForm from "./CreateTeamForm";

function CreateTeam() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {open && (
        <FormDialog
          open={open}
          handleClose={handleClose}
          title={"Invite User"}
          btnTitle={"Add"}
        >
          <CreateTeamForm onClose={handleClose} />
        </FormDialog>
      )}
      <Button
        onClick={handleClickOpen}
        className="capitalize theme-bg-color"
        variant="contained"
        startIcon={<AddIcon />}
      >
        Create Team
      </Button>
    </div>
  );
}

export default CreateTeam;
