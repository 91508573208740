import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Grid, ImageList } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { localStorageInstance } from "../../../../utils/localStorage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAttachments } from "../../../../sevices/apiBoards";
import toast from "react-hot-toast";
import Loader from "../../../../components/Loader";
import { objectToFormData } from "../../../../utils/helpers";
import InputFileUpload from "../../../../components/FileUploadButton";
import { useSelector } from "react-redux";

function Form({ name, file, handleNameChange, handleFileChange }) {
  // const { id } = useParams();
  // const queryClient = useQueryClient();
  // const userData = useSelector((state) => state.users.logInUser);
  // const [loading, setLoading] = useState(false);
  // const [name, setName] = useState([""]);
  // // const [image, setImage] = useState([{}]);
  // const [newData, setNewData] = useState([
  //   {
  //     name: "",
  //     image: null,
  //   },
  // ]);

  // React Hook Form
  const { register, handleSubmit, watch, reset, getValues, formState } =
    useForm({
      // defaultValues: {
      //   board_id: id,
      //   user_id: userData.user_id,
      // },
      // resolver: yupResolver(),
    });
  const { errors } = formState;

  console.log("file", file[0].file);
  return (
    <>
      {file?.map((file, index) => (
        <div key={index} className="attachments-form-container">
          <Box
            component="form"
            // onSubmit={handleSubmit(submit)}
            noValidate
            className="d-flex"
            sx={{
              bgcolor: "rgba(242, 244, 246, 1)",
              // marginTop: 4,
              padding: "8px",
              marginBottom: "5px",
              justifyContent: "space-between",
              border: "1px solid rgba(145, 158, 171, 0.12)",
              borderRadius: "8px",
              // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
            }}
          >
            <Grid
              container
              item
              xs={12}
              className="remove-container-width"
              justifyContent={"center"}
              alignItems={"center"}
              columnSpacing={2}
            >
              <Grid
                item
                xs={5}
                md={6}
                className="pl-0"
                sx={{ paddingLeft: "0" }}
              >
                <TextField
                  label={""}
                  placeholder="Name"
                  required
                  id="name"
                  name="name"
                  value={name[index]?.name || ""}
                  error={errors?.name ? true : false}
                  helperText={errors?.name && errors.name.message}
                  // {...register(`name_${index}`)}
                  sx={{
                    bgcolor: "white",
                    borderRadius: "4px",
                    border: "1px solid #919EAB40",
                  }}
                  onChange={(e) => handleNameChange(e, index)}
                />
              </Grid>
              <Grid item xs={5} md={6}>
                <InputFileUpload
                  fileName={file?.name}
                  watch={watch}
                  register={register}
                  getValues={getValues}
                  fileChange={(e) => handleFileChange(e, index)}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      ))}
    </>
  );
}

export default Form;
